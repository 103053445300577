/* Customization Guide available on:
     https://gatsby-minimalistic-dmin.netlify.com/2020/02/customize/
  ======================================== */
const config = {
  /* Site
  ========================================= */
  title: `{ Ellis Min }`, // Displayed in header
  description: `Personal blog by Ellis Min`, // Site description
  maxWidth: 860, // Max width of website
  enableAbout: true, // Enables about page
  siteUrl: `https://ellismin.com`,
  useScrollIndicator: false,

  /* Profile
  ========================================= */
  author: `Ellis Min`, // Name shows on profile
  profileDescription: "Keeping a record", // Shows under author name
  profileImageName: `profile.jpeg`, // Place profile.jpg/.jpeg/.png in _assets folder
  location: "", // Location under profileDescription. "" --> disabled
  footerLink: "https://github.com/ellismin", // Link to page when you click footer name

  /* Social Media Links
      accountName & emailAddress: leave blank to disable
      showHeaderIcon: shows social media icon in header. When true, must have account name set
  ========================================= */
  socialMediaLinks: {
    email: { emailAddress: "", showHeaderIcon: true },
    github: { accountName: "ellismin", showHeaderIcon: true },
    facebook: { accountName: "", showHeaderIcon: false },
    instagram: { accountName: "", showHeaderIcon: true },
    twitter: { accountName: "", showHeaderIcon: true },
    linkedIn: { accountName: "", showHeaderIcon: true }, // Use URL after "linkedin.com/"
    medium: { accountName: "", showHeaderIcon: true }, // Use URL after "medium.com/@"
  },

  /* Social Media Share Buttons--available below every post
  ========================================= */
  shareButtons: {
    email: true,
    facebook: true,
    twitter: true,
    reddit: false,
    linkedIn: false,
  },

  /* Comments
  ========================================= */
  comments: {
    facebook: { enabled: false, appId: "" },
    disqus: { enabled: false, shortName: "ellismin" },
    utterances: { enabled: true, repoUrl: "ellismin/blog-comments" },
  },

  /* More Custom Settings
  ========================================= */
  defaultTheme: "dark", // Options: dark / light -- applied to first visitors
  showTimeToRead: false, // Shows time to read for each post in main page
  breakCodeLines: false, // Breaks long code lines instead of horizontal scroll
  faviconSrc: `_assets/icon-code.png`, // Favicon
  gaTrackingId: `G-WCMMGNVEB6`, // Your google analytics tracking id--i.e. G-*****
  gaTrackingIdStale: `UA-159930243-1`, // old one that will be deprecated soon. TODO:
  googleAdSenseId: `ca-pub-7009296743214085`, // Your google AdSense client id--i.e. ca-pub-****
}

module.exports = config
